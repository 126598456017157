module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Take-Eat","short_name":"Your Favorite Take-Away Service","description":"Order Take-Eat food! Browse nearby restaurants that has take-way services. Get lunch & dinner, &amp; more. Order food in-app.","start_url":"","background_color":"#EBEDF2","theme_color":"#3D63AE","display":"standalone","icon":"assets/imgs/favicon.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[],"openGraph":{"type":"website","locale":"en_US","url":"https://take-eat.pt/","site_name":"Take-Eat"},"twitter":{"handle":"@takeeat","site":"@take-eat","cardType":"summary_large_image"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/builds/takeeat/rebranded-website/src/i18n/messages","languages":["en","pt"],"defaultLanguage":"en","redirect":true,"redirectComponent":"/builds/takeeat/rebranded-website/src/i18n/redirect.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-169952805-1","pageTransitionDelay":250,"cookieDomain":"take-eat.pt"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
