// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-condicoes-tsx": () => import("./../src/pages/condicoes.tsx" /* webpackChunkName: "component---src-pages-condicoes-tsx" */),
  "component---src-pages-contactos-tsx": () => import("./../src/pages/contactos.tsx" /* webpackChunkName: "component---src-pages-contactos-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-parcerias-tsx": () => import("./../src/pages/parcerias.tsx" /* webpackChunkName: "component---src-pages-parcerias-tsx" */),
  "component---src-pages-privacidade-tsx": () => import("./../src/pages/privacidade.tsx" /* webpackChunkName: "component---src-pages-privacidade-tsx" */),
  "component---src-pages-registo-tsx": () => import("./../src/pages/registo.tsx" /* webpackChunkName: "component---src-pages-registo-tsx" */),
  "component---src-pages-ui-kit-tsx": () => import("./../src/pages/ui-kit.tsx" /* webpackChunkName: "component---src-pages-ui-kit-tsx" */)
}

